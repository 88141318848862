.text-image-v3 {
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    z-index: 2;
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 29.91%, rgba(0, 0, 0, 0) 70.03%);
  }
  > * {
    z-index: 3;
  }
  .text-image__image {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
