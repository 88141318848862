
.single-portfolio__text {
    @screen sm {
        min-height: 100vh;
    }
}

.list-portfolio__item {
    position: relative;
    display: block;
    overflow: hidden;
    img {
        transition: 1.2s;
        transform: scale(1.01);
        width: 100%;
    }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.4) 9.57%, rgba(0, 0, 0, 0) 73.14%);
        z-index: 1;
    }
    
    &__inner {
        position: absolute;
        bottom: 0;
        left: 0;
        h3 {
            position: relative;
            display: inline-block;
            z-index: 1;
            &:before {
                content: '';
                width: 0%;
                height: 1.2rem;
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: -1;
                transition: .5s;
            }
        }
        span {
            display: block;
            position: relative;
            z-index: 1;
        }
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
        .list-portfolio__item__inner {
            position: absolute;
            bottom: 0;
            left: 0;
            h3 {
                &:before {
                    width: 100%;
                }
            }
        }
    }
}