@import "./v1/timeline";
@import "./v2/timeline";



.timeline {
  .timeline-arrow__wrapper {
    position: fixed;
    top: calc(100vh - 4rem);
    left: 0;
    width: 100%;
    z-index: 9;
    .arrow {
      cursor: pointer;
      svg {
        width: 4rem;
        height: auto;
        display: block;
        transition: 0.5s;
      }
      &:hover {
        svg {
          transform: translateY(0.5rem);
        }
      }
    }
  }
}