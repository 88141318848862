

@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@600;800&family=Meddon&display=swap');


#body-cs {
    height: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    // background-color: red;

    .main-cs {
        height: 100%;
        color: #fff;
        .cs {
            height: 100%;
            min-height: 100vh;
            overflow: hidden;
        }
        h4 {
            font-family: 'Meddon';
            font-style: normal;
            font-weight: 400;
            text-align: center;
            color: #fff;
            opacity: 0.1;
            font-size: 50px;
            line-height: 50px;
            @screen md {
                font-size: 90px;
                line-height: 190px;
            }
        }
        h3 {
            font-family: 'Bitter';
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            /* identical to box height */
            text-align: center;
            letter-spacing: 0.3em;
            color: #FFFFFF;
        }
        h2 {
            font-family: 'Bitter';
            font-style: normal;
            font-weight: 800;
            text-align: center;
            color: #FFFFFF;
            font-size: 28px;
            line-height: 34px;
            @screen md {
                font-size: 42px;
                line-height: 50px;
            }
        }

        .cs__timer {
            &__inner {
                div {
                    font-family: 'Futura';
                    font-style: normal;
                    font-weight: 700;
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 70px;
                    line-height: 90px;
                    @screen md {
                        font-size: 110px;
                        line-height: 150px;
                    }
                }
                span {
                    font-family: 'Futura';
                    font-style: normal;
                    font-weight: 500;
                    text-align: center;
                    display: block;
                    font-size: 20px;
                    line-height: 32px;
                    @screen md {
                        font-size: 30px;
                        line-height: 42px;
                    }
                }
            }
        }
        .title-1 {
            position: relative;
            h3 {
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
            }
        }
        .cs__foot {
            p {
                font-family: 'Futura';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 17px;
                color: #969696;
            }
            .social a {
                @apply ml-sm inline-block;
                opacity: .5;
                transition: .5s;
                svg {
                    path {
                        fill: #fff;
                    }
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
        .cs__head {
            img {
                width: 18rem;
                @screen md {
                    width: 23rem;
                }
            }
            .lang-switch {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                list-style: none;
                padding: 0 0 20px;
                display: flex;
                li {
                    @apply pl-sm;
                    @screen md {
                        @apply pl-md;
                    }
                    &.current-lang a {
                        font-weight: bold;
                    }
                }
            }
        }
        &--v1 {
            height: 100%;
            background-color: #141412;
            background-size: cover;
            background-position: center;
            
        }
        &--v2 {
            background-color: #FFFEF5;
            h4 {
                color: #000;
            }
            h3 {
                font-family: 'Futura';
                font-weight: 500;
                color: #000;
            }
            h2 {
                font-family: 'Futura';
                font-weight: 700;
                text-transform: uppercase;
                position: relative;
                svg, img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                }
            }
            .cs__timer__inner {
                position: relative;
                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -40%) scale(1.1);
                    z-index: -1;
                }
            }
            .cs__foot {
                p {
                    color: #fff;
                }
            }
            .button-youtube {
                font-family: 'Futura';
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
                text-align: center;
                color: #383838;
                border: 3px solid #F9B000;
                border-radius: 7px;
                display: inline-flex;
                align-items: center;
                padding: 7px 20px;
                background-color: #fff;
                svg {
                    transition: .5s;
                    margin-left: 5px;
                }
                &:hover {
                    svg {
                        transform: translateX(5px);
                    }
                }
            }
        }
    }

}

#scene {
    position: fixed;
    // bottom: -25vw;
    top: 20vh;
    left: 0;
    z-index: 1;
    width: 100%;
    img {
        zoom: 1.2;
        transform: scale(1.2);
        height: 70vh;
        width: auto;
        object-fit: cover;
    }
    @screen md {
        img {
            width: 100vw;
            height: auto;
        }
    }
}


.videoContainer {
    position: relative;
    height: 0;
    padding-top: 56.25%;
    width:100%;
    iframe {
        position: absolute;
        top: 0; left: 0;
        border: 0;
        width: 100%;
        height: 100%;
    }
}