.list-pt--v3 {
    position: relative;
    width: 100%;
    overflow: hidden;
    &:before {
      content: '';
      width: 50%;
      height: 80%;
      @apply bg-gray-light;
      position: absolute;
      left: 0;
      top: 0;
    }
    .bg-gray-light {
      height: 80%;
      position: absolute;
      left: -1rem;
      top: 0;
    }

    .card-pt-v3 {
        a {
            h2 {
                @apply mx-md my-lg;
                @extend .title-xs;
                &:before {
                    width: 6rem;
                }
            }
            &:hover h2:before {
                width: 4rem;
            }
        }
        img {
            object-fit: cover;
            height: 35rem;
            @screen lg {
                height: 35rem;
            }
        }
    }

}