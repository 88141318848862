.single_variation_wrap {
  width: 100%;
  .woocommerce-variation-add-to-cart {
    @apply flex flex-wrap justify-center;
    @screen lg {
      @apply justify-start;
    }

    .quantity {
      margin: 0;
      outline: none;
    }

    button {
    }
  }
}

form.cart {
  @apply flex flex-wrap;
  // margin-top: 2rem;
  width: 100%;

  .quantity {
    margin: 0;
    outline: none;
  }
}
