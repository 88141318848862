.content-prodotti {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 40rem;
        top: 0;
        left: 0;
        @apply bg-gray-light;
        z-index: -1;
    }
}