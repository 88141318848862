.button {
  &-v1 {
    transition: 0.5s;
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    position: relative;  
    padding: 15px;
    @extend .text-button;
    @apply text-white bg-brand_01 px-xl shadow-md rounded-sm items-center;
  
    svg {
      @apply ml-sm;
      transition: 0.5s;
      path {
        fill: theme('colors.white');
      }
    }
    .ico {    
      @apply bg-brand_01-dark rounded-sm;
      position: absolute;
      left: 10px;
      top: 10px;
      height: calc(100% - 20px);
      aspect-ratio: 1 / 1;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 2.2rem;
        transition: .3s;
      }
    }
    a {
      text-decoration: none;
    }
    @media (hover:hover) {
      &:hover {
        @apply shadow-none;
        @apply bg-brand_01-dark;
        .ico {    
          img {
            transform: scale(1.2)
          }
        }
      }
    }
  }
}
