.text-image {
    &__image {
        position: relative;
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            @apply rounded-sm;
            border: 1px solid;
            @apply border-gray-light;
            left: -0.5rem;
            top: -0.5rem;
            @screen md {
                left: -1.5rem;
                top: -1.5rem;
            }
        }
    }
}

.text-image-v1--reverse {
    .text-image {
        &__image {
            &:before {
                left: inherit;
                right: -0.5rem;
                top: -0.5rem;
                @screen md {
                    right: -1.5rem;
                    top: -1.5rem;
                }
            }
        }
    }
}