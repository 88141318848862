#body {
    // opacity: 0;
    // transition: 1s;
    // &.loaded {
    //     opacity: 1;
    // }
    main {
        >* {
            // opacity: 0;
            // transition: .5s;
            &.head.head--v1 {
                .parallax  {
                    opacity: 0;
                    transition: 1s;
                    transition-delay: 0s;
                    transform: translateY(-10px);
                    z-index: -1;
                }
            }
        }
    }
    &.loaded {
        main {
            >* {
                // opacity: 1;
                &.head.head--v1 {
                    .parallax  {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }
    }
}