.button {
  &-v3 {
    transition: 0.5s;
    text-decoration: none;
    display: inline-flex;
    @extend .text-button;
    @apply text-black px-md py-sm border-2 border-gray;
    a {
      text-decoration: none;
    }
    &--md {
      @extend .text-button;
    }
    &:hover, &--active {
      @apply border-brand_01 text-brand_01;
    }
  }
}
