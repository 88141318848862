// Single Swiper slider
// @import "./swiper/v1/slider";
// @import "./swiper/v2/slider";
// @import "./swiper/v3/slider";
// @import "./swiper/v6/slider";

// Multiple Swiper slider
// @import "./swiper/v2-multiple/slider";

//gallery
@import "./gallery/v1/slider";
@import "./gallery/v2/slider";

//posts
@import "./pt/v1/slider";

//products
// @import "./products/v1/slider";

//hero
// @import "./hero/v1/slider";

// custom
// @import "./custom/v1/slider";



.swiper-buttons {
  position: absolute;
  right: 2%;
  bottom: 2%;
  display: flex;
  align-items: center;
  z-index: 10;
  .swiper-button-next,
  .swiper-button-prev {
    left: initial;
    right: initial;
    position: initial;
    width: 100%;
    height: 100%;
  }

  .swiper-button-prev {
    margin-right: -2px;
  }
}
