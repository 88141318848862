.gallery__images {
  >div {
    width: 30%;
    // background-color: blue;
    // padding: 20px;
    &:nth-child(4n+1), 
    &:nth-child(4n+4)
    {
      width: 70%;
      // background-color: red;
      // padding: 20px;
    }
  }
  a {
    display: block;
    height: 100%;
  }
  img {
    // height: 6rem;
    // width: auto;
    object-fit: cover;
    height: 100%;
  }
}