
.header-top-v1 {
    ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        li {
            margin: 1rem 2rem;
            display: flex;
            a {
                @extend .text-sm;
                @apply font-bold;
                text-decoration: none;
                display: inline-block;
            }
        }
    }
}

