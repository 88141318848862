.content-post {
    .card-pt-v2 a .card-pt__img img {
        height: 30rem;
        object-fit: cover;
    }
}

.correlati {
    .card-pt-v2 a .card-pt__img img {
        height: 30rem;
        object-fit: cover;
    }
}