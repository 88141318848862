@import "./input-button/index.scss";
@import "./input-number/index.scss";

/// Inputs
input:focus,
* {
  outline: none;
}

input,
input[type="text"],
input[type="email"],
input[type="phone"],
textarea,
select {
  @apply px-sm py-xs border-gray-light border text-black rounded-sm;
  @extend .text-md;
  box-shadow: none;
  width: 100%;
  display: block;
  box-sizing: border-box;
  &::placeholder {
    @apply text-gray;
  }
}

// input,
// select,
// textarea {
//   margin-bottom: 1rem;
//   @extend .text-md;

//   &::placeholder {
//     color: black !important;
//   }
// }


select {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
    background: #fff url("../../../src/images/icons/arrow-select.svg");
  background-repeat: no-repeat;
  background-position: 95% 50%;
  background-size: 18px;
  padding-right: 40px;
}

.checkbox-inline,
.checkbox {
  display: block;
  width: 100%;
  margin: 10px 0;
  label,
  label p,
  label a {
    // @extend .text-sm;
    color: theme("colors.black.default");
    display: inline-block;
    margin-left: 0px !important;
  }
  p {
    margin-bottom: 0 !important;
  }
}

input[type="checkbox"] {
  @apply border-gray border rounded-sm;
  width: 18px !important;
  height: 18px !important;
  appearance: none;
  box-shadow: none;
  margin: 0 5px 0 0; //  cursor: pointer;
  display: inline-block;
  transition: 0.5s;
  vertical-align: middle;
  cursor: pointer;
  &:hover {
    @apply border-black-dark;
  }
  &:before {
    width: 6px;
    height: 12px;
    display: block;
    position: absolute;
    content: "";
    border-right: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(45deg) translate(-15px, -15px);
    color: #1e1e1e;
    margin: 0px 4px;
    opacity: 0;
    transition: 0.5s;
    color: theme("colors.ui.6");
  }
  &:checked {
    @apply border-black-dark;
    &:before {
      transform: rotate(45deg) translate(0px, 0px);
      opacity: 1;
    }
  }
}
input[type="radio"] {
  width: 18px !important;
  height: 18px !important;
  appearance: none;
  box-shadow: none;
  border: none;
  margin: 0 5px 0 0;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  transition: 0.5s;
  background-color: theme("colors.ui.2");
  border: 1px solid theme("colors.ui.3");
  cursor: pointer;
  vertical-align: middle;
  &:hover {
    border-color: theme("colors.ui.6");
  }
  &:before {
    width: 8px;
    height: 8px;
    display: block;
    position: absolute;
    content: "";
    background-color: theme("colors.ui.6");
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 0.5s;
  }
  &:checked {
    border-color: theme("colors.ui.6");
    &:before {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}



.wpcf7-form {
  .wpcf7-list-item {
    margin: 0;
  }
  .wpcf7-spinner {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin: 0 !important;
  }

  .container {
    padding: 0 !important;
  }

  .field.file {
    margin-bottom: 0rem;

    span {
      position: relative;
      // max-height: 44px;
      cursor: pointer;
      overflow: hidden;
      // @apply border-gray border;

      // &::before {
      //   cursor: pointer;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   content: "Carica CV";
      //   pointer-events: none;
      //   width: 100%;
      //   height: 100%;
      //   padding: 1rem;
      // }

      &::after {
        cursor: pointer;
        content: "";
        background-image: url("./imgs/document.svg");
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        width: 30px;
        height: 30px;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }

    input {
      margin-bottom: 0 !important;
    }
  }

  .field.select {
    .wpcf7-form-control-wrap {
      position: relative;

      &::after {
        content: "";
        background-image: url("./imgs/arrow.svg");
        display: block;
        position: absolute;
        top: 35%;
        right: 0;
        width: 30px;
        height: 30px;
        transform: translateY(-50%);
      }

      // &:hover {
      //   &::after {
      //     transform: translateY(-50%) rotate(-180deg);
      //   }
      // }
    }
  }

  .wpcf7-acceptance {
    cursor: pointer;
  }
  .wpcf7-checkbox,
  .wpcf7-acceptance {
    label {
      display: flex;
      input[type="checkbox"] {
        flex: none;
      }
      span {
        display: inline-block;
        line-height: 20px;
        margin-left: 5px;
        @extend .text-sm;
        a {
          @apply font-bold;
        }
      }
    }
  }

  .cf7sg-response-output {
    margin: 1rem 0 !important;
  }

  input[type="checkbox"],
  input[type="submit"] {
    margin-bottom: 0;
  }

  input[type="submit"].wpcf7-submit {
    @extend .button-v1;
    // @extend .button-v1--md;
    @apply mt-sm;
    width: auto;
    cursor: pointer;
    &:disabled {
      opacity: 0.4;
    }
  }

  label {
    em {
      display: none !important;
    }
  }

  span.wpcf7-not-valid-tip {
    padding: 3px 10px;
    background: theme('colors.support.error');
    color: #fff ;
    font-size: 12px;
    @apply rounded-sm mt-xs mb-sm;
  }
}
