.content-jobs {
  ul {
    margin: 1rem;
  }
  h2 {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    @apply w-full;
  }
  .o-job__img {
    img {
      object-fit: cover;
      height: 100%;
    }
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #000;
      opacity: .3;
      top: 0;
      left: 0;
    }
  }
}

.o-job {
  position: relative;
  .text-sm {
    h2, h3, h4, h5, h6 {
      @extend .title-sm;
    }
  }
  &.flex-row-reverse {
    &:before {
      left: 0;
    }
  }
}