.card-pt-v3 {
  a {
    position: relative;
    &::before {
      content: '';
      background: linear-gradient(360deg, rgba(0, 0, 0, 0.33) 19.32%, rgba(217, 217, 217, 0) 85.56%);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
    .card-pt__img {
      overflow: hidden;
      img {
        transition: 1s;
      }
    }
    h2 {
      position: absolute;
      bottom: 0;
      left: 0;
      display: inline-flex;
      align-items: center;
      z-index: 2;
      &:before {
        content: '';
        width: 10rem;
        height: 2px;
        display: block;
        background-color: #fff;
        margin-right: 1rem;
        transition: .8s;
      }
    }
    &:hover {
      .card-pt__img {
        img {
          transform: scale(1.1);
        }
      }
      h2 {
        &:before {
          width: 6rem;
        }
      }
    }
  }
}
