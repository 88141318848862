.woocommerce-message,
.woocommerce-info,
.woocommerce-error {
  position: relative;

  .notice-close {
    position: absolute;
    z-index: 100;
    top: 5%;
    right: 0;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}
