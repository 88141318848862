.tab-v2 {
    position: relative;
    .bg-gray-light--wrapper {
        position: relative;
        &::before {
        content: '';
        width: 50%;
        height: 100%;
        @apply bg-gray-light;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        }
    }
    .tab-inner {
      &__disable {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
      }
    }

    .tab__trigger {
        filter: grayscale(1);
        transition: .5s;
        cursor: pointer;
        img {
            height: 6rem;
            width: auto;
        }
        &:hover, &--active {
            filter: grayscale(0);
        }
    }
  
    .tab__info {
      @apply bg-white;
      transition: opacity 0.7s;
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 1rem;
      padding: 1rem;
  
      span {
      }
    }
  
    .gallery__item {
      &:hover {
        .tab__info {
          opacity: 1;
        }
      }
    }
  }
  


//   .tab-v2 {
//     position: relative;
//     &::before {
//       content: '';
//       width: 50%;
//       height: 100%;
//       @apply bg-gray-light;
//       position: absolute;
//       left: 0;
//       top: 0;
//       z-index: -1;
//     }
//     .tab-inner {
//       &__disable {
//         position: absolute;
//         top: 0;
//         left: 0;
//         opacity: 0;
//         pointer-events: none;
//       }
//     }
  
//     .tab__info {
//       @apply bg-white;
//       transition: opacity 0.7s;
//       position: absolute;
//       bottom: 0;
//       left: 0;
//       margin: 1rem;
//       padding: 1rem;
  
//       span {
//       }
//     }
  
//     .gallery__item {
//       &:hover {
//         .tab__info {
//           opacity: 1;
//         }
//       }
//     }
//   }
  