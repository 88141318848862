.slider-pt--v1 {
  position: relative;
  width: 100%;
  overflow: hidden;
  .swiper-container {
    // overflow: visible;
    overflow: hidden;
    @apply pb-md;
    margin: 0;

    // @media (min-width: 1024px) {
    //   padding-left: calc((100vw - 1024px) / 2);
    // }

    // @media (min-width: 1280px) {
    //   padding-left: calc((100vw - 1240px) / 2);
    // }
  }
  .swiper-slide {
    // width: auto !important;
    height: auto;

    // img {
    //   min-width: 30rem;
    //   object-fit: cover;
    //   height: 48vw;
    //   @screen lg {
    //     height: 60rem;
    //   }
    // }
  }


    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      left: inherit;
      right: inherit;
      top: inherit;
      width: 4.5rem;
      height: 4.5rem;
      @apply flex items-center justify-center bg-white rounded-full;
      border: 1px solid theme('colors.gray.light');
      transition: .5s;
      svg {
        transition: .5s;
        path {
          fill: theme('colors.black.default');
        }
      }
      &.swiper-button-disabled {
        cursor: initial;
        opacity: .3;
        svg {
          transform: none!important;
        }
      }
    }
    .swiper-button-next {
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      @screen xl {
        right: -6rem;
      }
      &:hover {
        svg {
          transform: translateX(5px);
        }
      }
    }
    .swiper-button-prev {
      top: 50%;
      left: 1rem;
      transform: translateY(-50%);
      @screen xl {
        left: -6rem;
      }
      &:hover {
        svg {
          transform: translateX(-5px);
        }
      }
    }

  .swiper-pagination {
    width: 100%;
    position: initial;

    .swiper-pagination-bullet {
      margin: theme("spacing.xs");
      border-radius: unset;
      width: 60px;
      height: 4px;
      background: theme("colors.gray.default");

      &-active {
        background: theme("colors.text.1");
      }
    }
  }

}
