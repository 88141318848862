// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
/**
 * Set up a decent box model on the root element
 */

//  html {
//   font-size: 6.25%;
// }
// body {
//   font-size: 16rem;
// }

:root {
  font-size: 0.625rem;
  

  // @media screen and (max-width: 1023px) {
  //   font-size: 8px;
  // }

  // @media screen and (max-width: 768px) {
  //   font-size: 7px;
  // }
}

html {
  box-sizing: border-box;
  // overflow-x: hidden;
}

body {
  // overflow-x: hidden;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

.outline-none {
  outline: none;
}

*:active,
*:focus {
  outline: none;
  // border: none;
}

b,
strong {
  font-weight: bold;
}
