

/* Popup */
.o-popup {
  &--newsletter {
    position: fixed;
    z-index: 999999;
    // background: rgba(0, 0, 0, 0.6);
    // display: block;
    // width: 100%;
    // height: 100%;
    // left: 0;
    // top: 0;
    .o-popup__body {
      // top: inherit;
      // left: 0;
      // bottom: 0;
      // transform: inherit;    
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 80rem;
    }
    &.o-popup--open {
      &:before {
        display: none;
      }
      .o-popup__body {
        pointer-events: all;
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
    .o-popup__container {
      position: relative;
      @apply p-0;
      overflow: hidden;
    }
    .o-popup__content {
      @apply p-md;
    }
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 9;
      padding: 10px;
      border: 2px solid #fff;
      cursor: pointer;
      transition: .5s;
      &_hover {
        @apply bg-brand_01;
      }
  }
    svg path {
      fill: #fff;
    }
    .o-popup__img {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: .2;
        z-index: 2;
      }
      img {
        width: 100%;
        z-index: 1;
      }
      h4 {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        @apply px-lg;
        text-align: center;
        color: #fff;
        transform: translateY(-50%);
        z-index: 3;
      }
    }
    input[type=checkbox] {
      border: 1px solid;
    }
  }
  
}

div#confirmationMessage {
  border: 2px solid #54bb54;
  padding: 8px;
  margin-top: 10px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
  color: #0c880c;
  text-align: center;
}