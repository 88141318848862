#main .woocommerce {
    max-width: 1200px;
    padding: 2rem;
    margin: 0 auto;
}

.checkout-button,
.woocommerce-button {
    @extend .button-v1;
    width: 100%;
}

button#place_order {
    margin-top: 2rem;
    @extend .button-v1;
    width: 100%;
  }
  

.button.wc-backward {
    @extend .button-v1;
}

.products {
    padding: 0;
    @apply pt-xl gap-lg;
    &.columns-3 {
        @screen md {
            @apply grid grid-cols-3;
        }
    }
    &.columns-4 {
        @screen md {
            @apply grid grid-cols-4;
        }
    }
}