
.head-home {
    position: relative;
    aspect-ratio: 1/1;
    @screen md {
        aspect-ratio: inherit;
        min-height: calc(100vh - $space_header);
    }
    &:before {
        content: '';
        @apply w-full h-full absolute left-0 top-0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 123.09%);
        // z-index: 1;
    }
    &__bg {
        @apply w-full h-full absolute left-0 top-0;
        object-fit: cover;
        z-index: -1;
    }
    a.arrow-down-home {
        display: block;
        position: absolute;
        left: 50%;
        bottom: 20px;
        transform: translateX(-50%);
    }
}