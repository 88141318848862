/**
 * Basic typography style for copy text
*/

@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
  font-family: "Roboto", sans-serif;
  // font-style: normal;
  // color: theme("colors.black.default");
  // -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: initial;
  padding-left: 2rem;
}
ol {
  list-style: decimal;
}

main p {
  margin: 1.5rem 0;
}




.title {
  &-xxl,
  &-xxl * {
    /* title/xxl */
    font-family: 'Courgette';
    font-style: normal;
    font-weight: 400;
    line-height: 80%;
    font-size: 4.5rem;
    @screen lg {
      font-size: 11rem;
    }
  }
  &-xl,
  &-xl * {
    /* title/xl */
    font-family: 'Courgette';
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    font-size: 4rem;
    @screen lg {
      font-size: 8rem;
    }
  }
  &-lg,
  &-lg * {
    /* title/lg */
    font-family: 'Courgette';
    font-style: normal;
    font-weight: 400;
    line-height: 80%;
    font-size: 4rem;
    @screen lg {
      font-size: 6rem;
    }
  }
  &-md,
  &-md * {
    /* title/md */
    font-family: 'Courgette';
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    font-size: 3.2rem;
    @screen lg {
      font-size: 4.5rem;
    }
  }
  &-sm,
  &-sm * {
    /* title/sm */
    font-family: 'Courgette';
    font-style: normal;
    font-weight: 400;
    line-height: 88%;
    font-size: 2rem;
    @screen lg {
      font-size: 3.5rem;
    }
  }

  &-xs,
  &-xs * {
    /* title/xs */
    font-family: 'Courgette';
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    font-size: 1.8rem;
    @screen lg {
      font-size: 3rem;
    }
  }
}







.text {
  &-lg,
  &-lg * {
    /* text/lg */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 130%;
    b,
    strong {
      font-weight: 700;
    }
  }
  &-md,
  &-md * {
    /* text/md */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    b,
    strong {
      font-weight: 700;
    }
  }
  &-sm,
  &-sm * {
    /* text/sm */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    b,
    strong {
      font-weight: 700;
    }
    a {
      // font-weight: 700;
      // text-decoration: underline;
    }
  }
  // &-xs,
  // &-xs * {
  //   /* text/xs */
  //   font-family: 'Futura';
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 12px;
  //   line-height: 16px;
  //   line-height: 150%;
  //   b,
  //   strong {
  //     font-weight: 700;
  //   }
  //   a {
  //     // font-weight: 700;
  //     // text-decoration: underline;
  //   }
  // }


  &-button {
    /* button/text */
    font-family: 'Courgette';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
  }
}


// .caption,
// .caption * {
//   /* caption */
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 700;
//   font-size: 16px;
//   line-height: 110%;
// }

blockquote,
.blockquote {
  /* blockquote */
  font-family: 'Courgette';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 130%;
}


.link {
  @extend .title-sm;
  @apply text-black-dark;
  transition: 0.5s;
  text-decoration: none;
  &:hover {
    @apply text-black;
  }
}

// .link {
//   &-l {
//     font-family: Montserrat;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 2rem;
//     letter-spacing: 0.1em;
//     text-decoration-line: underline;
//   }
//   &-m {
//     font-family: Montserrat;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 1.4rem;
//     letter-spacing: 0.1em;
//     text-decoration-line: underline;
//   }

//   &-s {
//     font-family: Montserrat;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 1.2rem;
//     letter-spacing: 0.1em;
//     text-decoration-line: underline;
//   }
// }



.label {
}

.text {
}

@layer base {
  body,
  p {
    @extend .text-md;
  }
  body h1 {
    @extend .title-xl;
  }
  body h2 {
    @extend .title-lg;
  }
  body h3 {
    @extend .title-md;
  }
  body h4 {
    @extend .title-sm;
  }
  body h5 {
    @extend .title-xs;
  }
  body h6 {
    @extend .title-xs;
  }

  body blockquote {
    @extend .title-sm;
  }
}

.single-post__content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    b,
    strong {
      @apply text-black-dark;
    }
  }
}

p,
ul,
li {
  &.has-xxl-font-size {
    @extend .text-lg;
  }
  &.has-xl-font-size {
    @extend .text-lg;
  }
  &.has-lg-font-size {
    @extend .text-lg;
  }
  &.has-md-font-size {
    @extend .text-md;
  }
  &.has-sm-font-size {
    @extend .text-sm;
  }
  &.has-xs-font-size {
    @extend .text-sm;
  }
  &.has-xxs-font-size {
    @extend .text-sm;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.has-xxl-font-size {
    @extend .title-xxl;
  }
  &.has-xl-font-size {
    @extend .title-xl;
  }
  &.has-lg-font-size {
    @extend .title-lg;
  }
  &.has-md-font-size {
    @extend .title-md;
  }
  &.has-sm-font-size {
    @extend .title-sm;
  }
  &.has-xs-font-size {
    @extend .title-xs;
  }
  &.has-xxs-font-size {
    @extend .title-xs;
  }
}

.text-white {
  p,
  ul,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }
}
