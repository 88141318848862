// .woocommerce .woocommerce-breadcrumb {
//   @extend .text-md;
//   color: theme("colors.text.1") !important;
//   margin: 0;

//   a {
//     color: theme("colors.gray.default") !important;
//   }
// }


.woocommerce-breadcrumb {
    color: theme('colors.ui.7');
    font-weight: 500;
    a {
        color: theme('colors.ui.5');
        font-weight: 300;
        transition: .5s;
        &:hover {
            color: theme('colors.ui.7');
        }
    }
}