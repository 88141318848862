.colcade > div {
  flex: 1;
}
/* with flexbox */
.colcade {
  display: grid;
  grid-template-columns: 50% 50%;
}

.colcade-col {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}



.colcade-item {
  &__img {
    position: relative;
    overflow: hidden;
    img {
      transform: scale(1.1);
      transition: 1s;
    }
  }
  &:hover {
    .colcade-item__img {
      img {
        transform: scale(1);
      }
    }
    .button-v1 hr {
      transform: translateY(-4px);
    }

  }
}