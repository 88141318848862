.single-post__head {
  padding-bottom: 25rem;
  position: relative;
  background-color: rgba($color: #000000, $alpha: 0.5);
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}

.single-post__content {
  margin-top: -20rem;
}

.single-post__cats {
  a {
    font-weight: bold;
  }
}
