.o-multiple-shipping-type {
    p {
      font-size: 1.3rem !important;
    }
  }
  
  .cart-collaterals {
    padding: 2rem 3rem;
    max-width: 400px;
    margin-left: auto;
    @apply shadow-md;
    @apply rounded-md;
    @apply mt-md;

    @extend .text-sm;
    label,
    p {
      @extend .text-sm;
      margin: 0;
    }
    h2 {
      margin-bottom: 1rem;
    }
    .wc-proceed-to-checkout .button.checkout-button {
    //   @extend .button-m;
    //   @extend .button-m--orange;
      margin-top: 2rem;
  
      &.disable {
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }
  
  .woocommerce .shop_table {
    text-align: left;
    border-collapse: collapse;
    width: 100%;
    p {
      margin: 0;
    }

    .product-name {
      font-weight: bold;
      dl.variation {
        margin-top: .5rem;
        *, p {
          line-height: 22px;
          font-size: 14px;
        }
        dt, dd {
          float: left;
          font-weight: normal;
          
        }
        dt {
          clear: both;
          margin-right: 0.5rem;
        }
      }
    }


    li {
      list-style: none;
    }
    thead {
        border-bottom: 2px solid;
    }

    .cart_item, .cart-subtotal {
        border-bottom: 1px solid theme('colors.ui.4');
    }
    .order-total {
        border-bottom: 1px solid theme('colors.ui.6');
    }
    .quantity {
        box-shadow: none;
    }
  
    th,
    td {
    //   border: 1px solid theme("colors.black.light");
      padding: 5px 10px;
    }
    .product-thumbnail {
      width: 100px;
    }
    .coupon {
      display: flex;
      align-content: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      label {
          display: none;
      }
      #coupon_code {
        max-width: 280px;
        margin: 0 1rem;
      }
      button {
        @extend .button-v1;   
        // @extend .button-v1--sm; 
      }
      @media (max-width: theme("screens.md")) {
        justify-content: flex-start;
        button {
          width: 100%;
        }
        #coupon_code {
          max-width: 100%;
          margin: 1rem 0;
        }
      }
    }
    td > button.button {
        margin-left: auto;
        display: block;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        @extend .button-v1;    
        // @extend .button-v1--transparent;    
        // @extend .button-v1--sm;    
        float: right;
        &[aria-disabled="true"] {
            opacity: .5;
            pointer-events: none;
        }
      @media (max-width: theme("screens.md")) {
        margin-left: 0;
      }
    }
    a.remove {
      font-size: 4rem;
      color: theme("colors.red");
    }
  
    @media (max-width: theme("screens.md")) {
      thead {
        display: none;
      }
      tr.woocommerce-cart-form__cart-item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 3rem;
        td {
          text-align: right;
          width: 100%;
          &::before {
            content: attr(data-title) ": ";
            font-weight: 700;
            float: left;
          }
          &.product-remove,
          &.product-thumbnail {
            &::before {
              display: none;
            }
          }
          &.product-thumbnail img {
            max-width: 150px;
            margin: 0 auto;
          }
          &.product-quantity .quantity {
            max-width: 100px;
            margin: 0;
            margin-left: auto;
          }
        }
      }
    }
  } //shop_table
  
  .woocommerce .coupon button.button {
    padding: 0.3rem 1.4rem;
    background: theme("colors.black.light");
    color: #fff;
    text-transform: uppercase;
    @extend .text-md;
  }
  
//   .woocommerce-message {
//     background: theme("colors.orange.default");
//     text-align: center;
//     padding: 1rem;
//     margin-bottom: 1rem;
//   }


  /*****
  checkout
  ****/
  
  .woocommerce-checkout-payment {
    li {
      list-style: none;
    }
  }
  
  /* 2 column layout */
  @media (min-width: 981px) {
    .woocommerce-checkout .woocommerce {
      overflow: hidden;
    }
    .woocommerce-checkout .woocommerce:after {
      clear: both;
    }
    .woocommerce-checkout .woocommerce .col2-set .col-1,
    .woocommerce-checkout .col2-set .col-1 {
      width: 100%;
    }
    .woocommerce-checkout .woocommerce .col2-set,
    .woocommerce-checkout .woocommerce-page .col2-set {
      float: left;
      width: 48%;
    }
    .woocommerce-checkout #order_review_heading,
    .woocommerce-checkout .woocommerce #order_review,
    .woocommerce-checkout .woocommerce-page #order_review {
      float: left;
      width: 48%;
      margin-left: 4%;
    }
  
    .woocommerce-billing-fields__field-wrapper,
    .woocommerce-shipping-fields__field-wrapper {
      display: flex;
      flex-wrap: wrap;
    }
  
    .form-row {
      width: 50%;
      @apply my-xs;
      &-first {
        padding-right: 0.5rem;
      }
      &-last {
        padding-left: 0.5rem;
      }
      &-wide,
      &.notes,
      &.place-order {
        width: 100%;
      }
    }
    .woocommerce-terms-and-conditions-wrapper {
        .form-row {
          width: 100%;
        }
    }
  }
  
  form.checkout.woocommerce-checkout {
    margin: 3rem 0;
    label,
    p {
      @extend .text-sm;
    }
  }
  // .col2-set {
  //   display: flex;
  //   > div {
  //     padding: 0 2rem;
  //     flex: 1;
  //   }
  // }
  
  .blockOverlay {
    background: #000 !important;
    opacity: 0.8 !important;
  }
  
  .payment_methods {
    label {
      padding: 1rem;
      display: inline-block;
      img {
        display: inline-block;
        margin-left: 2rem;
      }
    }
  }
  
  ul.woocommerce-error {
    margin-bottom: 20px;
    padding: 1rem 0;
    background: #fff;
    @apply shadow-sm;
    @apply rounded-md;
    border: 1px solid theme('colors.support.error');
    li {
      padding: 0.5rem 1.5rem;
      @extend .text-sm;
    }
  }

  .woocommerce-form-coupon-toggle,
  .woocommerce-form-login-toggle {
    // width: 100%;
    // padding: 1rem 1.5rem;
    // background: #fff;
    // @apply shadow-sm;
    // @apply rounded-md;
    margin-top: 2rem;
    .woocommerce-info {
        @extend .title-xs;
        text-transform: uppercase;
        color: theme('colors.text.2');
        @apply flex;
        @apply flex-wrap;
        @apply items-center;
    }
    .showcoupon,
    .showlogin {
        @extend .button-v1;
        // @extend .button-v1--transparent;
        // @extend .button-v1--sm;
        text-transform: initial;
        @apply ml-sm;

        @media screen and (max-width: 980px) {
          width: 100%;
          margin: 1rem 0 0;
        }

    }
  }


  .clear {
    clear: both;
    flex-basis: 100%;
    height: 0;
  }


  .woocommerce-form-coupon,
  .woocommerce-form-login {
    width: 100%;
    padding: 1rem 1.5rem;
    background: #fff;
    @apply shadow-sm;
    @apply rounded-sm;
    @apply mt-md;
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
    p {
      @extend .text-md;
    }
    .form-row-first {
      max-width: 230px;
      margin-left: auto;
    }
    .form-row-last {  
      width: auto;
      button {
        @extend .button-v1;
        // @extend .button-v1--sm;
      }
    }
  }
  .woocommerce-form-login {
    >* {
      flex-basis: 100%;
    }
    .form-row-first,
    .form-row-last {
      max-width: 100%;
      flex-basis: 50%;
      @media screen and (max-width: 980px) {
        flex-basis: 100%;
      }
    }
    .woocommerce-form-login__rememberme {
      width: 100%;
      display: block;
    }
    .button {
      margin-top: 1rem;
      width: auto;
    }
  }
  
  fieldset#wc-stripe-cc-form {
    border: 0px;
    label {
      display: none;
    }
  }
  
  .woocommerce-NoticeGroup {
    ul li {
      list-style: none;
    }
  }
  
  .woocommerce-error {
    li {
      list-style-type: none;
    }
  }
  
//   .woocommerce-notices-wrapper > div.woocommerce-error,
//   .woocommerce-notices-wrapper > ul.woocommerce-error {
//     margin: 0;
//   }


.woocommerce-shipping-methods {
  padding-left: 0 !important;
  li {
    margin: 1rem 0;
  }
}
  