.gallery__links {
  a {
    position: relative;
    img {
      transition: .8s;
    }
    &:before {
      content: '';
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 62.55%, #FFFFFF 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
    }
    .title-sm {
      position: absolute;
      bottom: 0;
      left: 2rem;
      transition: .5s;
      z-index: 99;
    }
    @media (hover:hover) {
      &:hover {
        img {
          transform: scale(1.1);
        }
        .title-sm {
          transform: translateX(1rem);
        }
      }
    }
  }
}
