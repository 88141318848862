.pagination-v1 {
    display: flex;
    justify-content: center;
    align-items: center;
    span,
    a {
      width: 5rem;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @extend .text-sm;
      margin: 0.5rem;
      transition: 0.5s;
      text-decoration: none;

      @apply border-gray border text-black rounded-sm;
      path {
        transition: 0.5s;
      }

      &.current{
        @apply border-brand_01 text-white bg-brand_01;
        pointer-events: none;
      }

      &.next,
      &.prev {
        @apply text-black;
        @apply bg-transparent;
        svg {
          path {
            stroke: theme("colors.black.default");
          }
        }
      }

      &:hover {
        @apply border-brand_01 text-brand_01;
        svg {
          path {
            stroke: theme("colors.brand_02.default");
          }
        }
      }
    }
  }

