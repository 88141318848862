.button-v2 {
  @extend .text-button;
  @apply text-brand_01 inline-flex items-center whitespace-no-wrap;
  transition: 0.5s;
  text-decoration: none;
  &:before {
    content: '';
    width: 4rem;
    height: 2px;
    display: block;
    @apply bg-brand_01 mr-sm;
    transition: .5s;
  }
  a {
    text-decoration: none;
  }
  &:hover, &--active {
    &:before {
      width: 3rem;
    }
  }
}
