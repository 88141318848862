.list-v1 {
   
}


.underline-title,
.menu-tendina__inner li {
    display: flex;
    align-items: flex-end;
    h2, a {
        display: inline-block;
        padding-right: 10px;
    }
    &::after {
        content: '';
        width: 100%;
        height: 1px;
        display: inline-block;
        @apply bg-black;
        flex: 1;
    }
}
.menu-tendina__inner li {
    &::after {
        @apply bg-white;
    }
}