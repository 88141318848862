.card-v1 {
  .card__head {
    position: relative;
    @apply px-md;
    @apply py-sm;
    svg {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    img {
      width: 3rem;
    }
  }
  .card__body {
    @apply px-md;
    @apply pb-md;
  }
  .card__url {
    @apply px-md;
    @apply pb-md;
  }
}