.timeline {
  &--v2 {
    .timeline {
      &__line {
        background-color: theme("colors.orange.default");
        position: absolute;
        top: 0;
        left: 0;
        width: 0.4rem;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        height: 0;
        z-index: -1;
      }

      &__item {
        max-width: 100%;
        position: relative;
        opacity: 0;
        transition: 0.8s;
        transform: translateY(2rem);
        &--animating {
          opacity: 1;
          transform: translateY(0rem);
        }
        &__img {
          overflow: hidden;
          position: relative;
          img {
            display: block;
            width: 100%;
          }
          span {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            display: block;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0em;
            text-align: center;
            color: #fff;
            opacity: 0.7;
            text-shadow: 0px -2px 16px rgba(0, 0, 0, 0.5);
            font-size: 7rem;
            line-height: 6rem;
            @screen sm {
              font-size: 12rem;
              line-height: 11rem;
            }
            @screen md {
              font-size: 15rem;
              line-height: 13rem;
            }
            @screen lg {
              font-size: 25rem;
              line-height: 22rem;
            }
          }
        }
        &-wrapper {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            @apply shadow-md rounded-full;
            z-index: -1;
            width: 6rem;
            height: 6rem;
            display: block;
            background-color: theme("colors.orange.default");
            left: -2rem;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}
