


.timeline {
	&-v1 {
		.timeline {
			&__line {
				@apply bg-brand_01;
				position: absolute;
				top: 0;
				width: 1px;
				-webkit-transform: translateX(-50%);
				transform: translateX(-50%);
				height: 0;
				z-index: -1;
				left: 1.5rem;
				@screen md {
					left: 50%;
				}
			}



			&__item {
				max-width: 50rem;
				position: relative;
				opacity: 0;
				transition: .8s;
				transform: translateY(2rem);
				.data {
					@extend .title-xxl;
					@apply text-brand_01 w-full px-md -mb-lg relative;
				
				}
				&.flex-row-reverse {
					.data {
						text-align: right;
					}
				}
				img {
					@apply rounded-md shadow-sm;
				}
				&__text {
					@screen md {
						position: absolute;
						top: 100%;
						left: 0;
					}
				}
				&--animating {
					opacity: 1;
					transform: translateY(0rem);
				}
				&-wrapper {
					position: relative;
					&::before {
						content: '';
						position: absolute;
						@apply shadow-md rounded-full;
						z-index: -1;
						width: 1.5rem;
						height: 1.5rem;
						display: block;
						@apply bg-brand_01;
						top: 50%;
						transform: translate(-50%, -50%);
						left: 0.5rem;
						@screen md {
							left: 50%;
						}
					}
				}
			}
		}
	}
}