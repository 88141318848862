.card-pt-v1 {
  a {
    position: relative;
    transition: .5s;
    .card-pt__img {
      overflow: hidden;
      img {
        transition: 1s;
      }
    }
    h2 {
      
    }
    &:hover {
      .card-pt__img {
        img {
          transform: scale(1.1);
        }
      }
      h2 {
      }
    }
  }
}
