.price {
  @extend .title-sm;
}


.price .woocommerce-Price-amount,
.woocommerce-Price-amount {
  color: theme("colors.brand_02.default");
}


.price del, .price del .woocommerce-Price-amount {
  opacity: 1 ;
  color: theme("colors.gray.default");
}

.woocommerce-Price-currencySymbol {
  @apply pr-xs;
}

.price ins,
ins {
  text-decoration: none;
  @apply pl-xs;
  .woocommerce-Price-amount {
    color: theme("colors.brand_02.default");
  }
}


.price.w-full {
  color: theme("colors.black.default") ;
  margin: 2rem 0 1rem;
  // @extend .title-m;

  del {
    color: inherit;
    opacity: 0.5;
    display: inline-block;
  }

  ins {
    background: none;
    font-weight: 700;
    display: inline-block;
    color: theme("colors.support.error");
  }

  .from {
    font-size: 0.67em;
    margin: -2px 0 0 0;
    text-transform: uppercase;
    color: theme("colors.text.1") ;
    opacity: .7;
  }
}