.o-popup {
  position: fixed;
  z-index: 999999;
  &--open {
    .o-popup__body {
      pointer-events: all;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
      transition: transform 0s, opacity 0.5s;
    }
  }

  .o-popup__body {
    pointer-events: none;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, -150%);
    z-index: 9999;
    transition: transform 0s, opacity 0.5s;
    z-index: 99;

    width: 100%;
    max-width: 80rem;
    height: auto;

    overflow-y: auto;
    max-height: 100%;
  }
}

.o-popup__close {
  // position: absolute;
  top: 8px;
  right: 8px;
  width: 6rem;
  height: 6rem;
  cursor: pointer;
  transition: .5s;

  > div {
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@import "./newsletter-popup/newsletter-popup";
// @import "./cookies-popup/cookies-popup";
// @import "./popup-info-prodotto/index.scss";
// @import "./post-soluzioni-popup/index.scss";
// @import "./search-popup/index.scss";
