.checkout.woocommerce-checkout {
  float: left;
  .checkout-btn-container {
    button#place_order {
      margin: 0;
    }
    .loader {
      top: 50%;
      transform: translateY(-50%);
      height: 105%;
      display: none;
      pointer-events: none;
    }
  }

  &.processing {
    .checkout-btn-container {
      .loader {
        display: flex;
        pointer-events: all;
      }
    }
  }
}

.woocommerce-additional-fields {
  @apply mt-md;
}

.payment_methods {
  padding-left: 0rem !important;
  margin-top: 1.5rem;

  .payment_method_stripe {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    label {
      display: flex;
      align-items: center;
      width: 70%;
    }
    .payment_box.payment_method_stripe {
      width: 100%;

      fieldset {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }

      .form-row.woocommerce-SavedPaymentMethods-saveNew.woocommerce-validated {
        width: 100%;
        display: flex;
        align-items: center;

        label {
          width: 100%;
        }
      }
    }
  }
}

.payment_box {
  background: theme("colors.ui.6");
  padding: 2rem;
  margin-top: 1rem;
  position: relative;
  @apply rounded-sm;
  p {
    margin: 0;
    color: #fff;
  }
  &:before {
    content: "";
    width: 20px;
    height: 20px;
    background: theme("colors.ui.6");
    display: block;
    top: -7px;
    left: 4rem;
    position: absolute;
    transform: rotate(45deg);
  }
}

.blockUI.blockOverlay {
  background: rgba(255, 255, 255, 0.7) !important;
  z-index: 10 !important;
}

.wooccm-field.wooccm-field-wooccm11 {
  margin-bottom: 0px;

  label {
    margin-bottom: 0px;
  }
}

#customer_details {
  .woocommerce-additional-fields {
    margin-top: 0px;
  }
}
