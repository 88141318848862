.card-product {
  &--v1 {
    max-width: 100%;
    transition: 0.5s;
    // width: 30rem;
    // @screen sm {
    //   width: 38rem;
    // }
    .card-product {
      &__image {
        border: 2px solid theme("colors.gray.light");
        width: 100%;
        min-height: 41rem;
      }
      &__content {
        // min-height: 18rem;
        // min-height: 22rem;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
      }

      &__notification {
        position: absolute;
        top: 1rem;
        right: 1rem;
        > div {
          @apply rounded-full;
          @apply px-md;
          @apply py-xs;
          @extend .text-sm;
        }
      }

      &__low-stock {
        background-color: theme("colors.gray.dark");
        color: #fff;
        // @extend .text-s--uppercase;
      }

      &__out-of-stock {
        @apply bg-support-error;
        color: #fff;
        // @extend .text-s--uppercase;
      }
      &__new {
        @apply bg-brand_01;
        color: #000;
        // @extend .text-s--uppercase;
      }

      &__onsale {        
        @apply bg-support-success;
        color: #fff;
        // @extend .text-s--uppercase;
      }

      &__free-ship {
        @apply bg-support-success;
        color: #fff;
        // @extend .text-s--uppercase;
      }
    }
  }
}
