.text-video {
  &-v1 {
    .text-video__image-video {
      transition: opacity 0.7s;

      &--playing {
        opacity: 0;
      }
    }
    .text-video {
      &__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 10;
        opacity: 0;
        transition: opacity 0.7s;

        &--playing {
          background: black;
          opacity: 1;
          pointer-events: all;
        }
      }
      &__inner {
    
      }
      &__image {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-2rem);
        opacity: 0.6;
      }

      &__play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &__placeholder {
        .gradient {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
}
