.tab-v1 {
  position: relative;
  &::before {
    content: '';
    width: 50%;
    height: 100%;
    @apply bg-gray-light;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .tab-inner {
    &__disable {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
    }
  }

  .tab__info {
    @apply bg-white;
    transition: opacity 0.7s;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 1rem;
    padding: 1rem;

    span {
    }
  }

  .gallery__item {
    &:hover {
      .tab__info {
        opacity: 1;
      }
    }
  }
}




.o-files {
  img {
    max-width: 62px;
    margin: 0 auto;
  }

  a.text-md.rounded-md.block.no-underline {
    transition: opacity 0.7s;
    &:hover {
      opacity: 0.4;
    }
  }
}

// .tab-container-files {
//   display: grid;
//   grid-template-columns: auto;
//   justify-content: center;
//   gap: 1rem;

//   .button-v2 {
//     grid-row-start: 1;
//   }

//   @media screen and (max-width: 1100px) {
//     grid-template-columns: repeat(3, 1fr);

//     .button-v2 {
//       grid-row-start: auto;
//     }
//   }

//   @media screen and (max-width: 800px) {
//     grid-template-columns: repeat(2, 1fr);

//     .button-v2 {
//       grid-row-start: auto;
//     }
//   }

//   @media screen and (max-width: 600px) {
//     grid-template-columns: repeat(1, 1fr);
//   }
// }
