$space-header--mob: 8rem;
$space-header: 11rem;

.space-header {
  height: $space-header--mob;
  display: block;
  @screen md {
    height: $space-header;
  }
}


.logo {
  position: relative;
  a {
    display: block;
  }
  img {
    width: 13rem;
    @screen md {
      width: 16rem;
    }
  }
}


.header-v1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  transition: .5s;
  height: $space-header--mob;
  @screen md {
    height: $space-header;
  }
  background-color: #fff;
  @apply py-md shadow-md;
  @apply text-black;
  // background-color: #fff;

  // @media screen and (max-width: 767px) {
  //   padding: 1rem;
  //   z-index: 999999;
  // }



  .header__controls {
    z-index: 1000;
  }


  &--scroll {
    background-color: #fff;
    @apply py-sm shadow-md;
  }

  .ico-head {
    width: 4rem;
    height: 4rem;
    @apply flex items-center justify-center rounded-full bg-gray-extralight ml-sm;
    transition: .5s;
    svg path {
      transition: .5s;
    }
    &:hover {
      @apply bg-brand_01;
      svg path {
        stroke: #fff;
      }
    }
  }
}





.header-mobile__trigger {
  position: fixed;
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  top: 1.8rem;
  right: 2rem; 
  @screen md {
    top: 3rem;
    right: 3rem; 
  }
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background-color: #000;
}
.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
  background-color: #fff;
}




.menu-tendina {
  z-index: 99;
  left: 0;
  top: 0;
  transform: translateY(calc(-100% - 1rem));
  transition: .5s;
  overflow: scroll;
  width: 100%;

  // @media screen and (max-width: 767px) {
  //   padding: 10rem 0;
  // }
  &--active {
    transform: translateY(0%);
  }
  &__head {
    height: 12rem;
  }
  &__inner {
    height: calc(100% - 24rem);
    display: flex;
    align-items: center;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  .title-lg {
    li {
      @apply my-lg;
      transition: .5s;
      &:hover {
        transform: translateX(10px);
      }
      a {
        @extend .title-md;
      }
    }
  }
  .text-md {
    li {
      @apply my-md;
      @apply text-gray;
      transition: .5s;
      &:hover {
        @apply text-black-dark;
        transform: translateX(5px);
      }
    }
  }
  &__foot {
    position: absolute;
    bottom: 0;
    left: 0;
  }

}

.menu__social {
  a {
    transition: .5s;
    &:hover {
      opacity: .7;
    }
  }
}




.lista-orari {
  opacity: 0;
  transition: 1.2s;
  transform: translateY(2rem);
  pointer-events: none;
  &--active {
    opacity: 1;
    transform: translateY(0rem);
    pointer-events: inherit;
  }
  &__item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}


.close-orari {
  display: none;
}

@media screen and (max-width: 767px) {
  .menu-tendina .menu {
    transition: .5s;
    opacity: 1;
  }
  .menu-tendina--orari {
    .menu {
      opacity: 0;
    }
  }
  .close-orari {
    display: flex;
    svg {
      width: 4rem;
      height: 4rem;
    }
  }
}