.single-product-wrapper {
  .sticky {
    top: 14rem;
  }
}

.product_title {
  @extend .title-lg;
  font-weight: bold;
  @apply mb-md w-full;
}

.woocommerce-product-details {
  &__short-description {
    // @apply max-w-md;
    @apply text-black-light;
  }
}

//accorion tabs
.panel-heading {
  &--active {
  }
}



// Notice wrapper
.woocommerce-notices-wrapper {
  position: fixed;
  top: 50%;
  right: 0;
  width: 25rem;
  transform: translateY(-50%);
  z-index: 99;
  > div,
  > ul {
    padding: 1rem 2rem;
    background: white;
    margin: 1rem;
    @apply shadow-md;
    @apply rounded-md;
    .button {
      background: theme("colors.yellow.default");
      font-weight: bold;
      transition: 0.5s;
      &:hover {
        background: theme("colors.yellow.hover");
      }
    }
  }
}

.wcppec-checkout-buttons__separator {
  display: none;
}

// Product
.upsells.up-sell {
  overflow-x: hidden;
}

.woocommerce-variation-price {
  @apply py-sm;
}
.reset_variations {
  display: inline-block;
  box-sizing: border-box;
  padding: 0.3rem 2em;
  border-radius: 3rem;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  transition: .5s;
  cursor: pointer;
  text-align: center;
  color: #fff;
  @extend .text-sm;
  @apply uppercase;
  position: relative;
  font-weight: 500;
  margin: 1rem 0 0.5rem;
  background-color: theme('colors.support.error');
  &:hover {
    background-color: #ff0000;
  }
}


.product_meta {
  .posted_in,
  .tagged_as,
  .sku_wrapper,
  .yith-wcbr-brands {
    display: none;
  }
  .yith-wcbr-brands-logo {
    border-top: 1px solid theme('colors.gray.light');
    @apply mt-md pt-md;
    a {
      display: block;
      img {
        height: 4.5rem;
        width: auto;
        @apply rounded-sm;
        display: block
      }
    }
  }
}


.product-shop-info {
  >div {
    @apply flex flex-wrap items-center mb-sm;
    span {
      @apply mx-sm;
    }
  }
}


.woocommerce .panel .collapse h2 {
  display: none;
}


.panel-group {
  @apply mt-md;
  .panel {
    @apply py-md border-b border-gray-light;
    .panel-heading {
      cursor: pointer;
      svg {
        transform: rotate(180deg);
        transition: .5s;
      }
      &--active {
        svg {
          transform: rotate(0);
        }
      }
    }
    .panel-collapse {
      @apply py-md;
      @extend .text-md;
      @apply text-gray-dark;
    }
  }
}

.variations {
  tr {
    th, td {
      width: 100%;
      display: block;
      label {
        @extend .text-sm;
        font-weight: 700;
        @apply uppercase;
      }
    }
  }
}