.footer {
  &--v1 {
    position: relative;

    img {
      width: 16rem;
    }
    h6 {
      @extend .text-md;
      font-weight: bold;
    }
    p,
    a {
      // @extend .text-sm;
      // @apply text-gray;
    }
    .info-azienda p {
      margin: 1.5rem 0;
    }
    p a {
      font-weight: 700;
    }
    ul {
      float: left;
      padding: 0;
      li {
        @apply mt-xs;
        list-style: none;
        a {
          transition: 0.5s;
          &:hover {
            @apply text-white;
          }
        }
      }
    }
    .menu__social {
      svg {
        width: 3rem;
        width: 3rem;
      }
    }
  }
}



// .social-feed {
//   position: relative;
//   .button-v1 {
//     position: absolute;
//     bottom: 0;
//     left: 50%;
//     transform: translate(-50%, 50%);
//     z-index: 99;
//   }
// }

// .form-newsletter {
//   position: relative;
//   &__onde {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     img {
//       position: absolute;
//       width: 100%;
//       top: 50%;
//       left: 0;
//       transform: translateY(-50%);
//     }
//   }
// }