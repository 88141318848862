.slider-gallery-v1 {
  overflow: hidden;
  .swiper-container {
    overflow: visible;
  }
  .swiper-slide {
    width: auto !important;
    height: 60rem;
    // width: 100%;

    img {
      width: auto;
      height: 100%;
    }

    @media screen and (max-width: 767px) {
      height: auto;
      img {
        height: auto;
        max-height: 20rem;
      }
    }
  }

  // .swiper-button-prev,
  // .swiper-button-next {
  //   position: relative;
  //   left: inherit;
  //   right: inherit;
  //   top: inherit;
  //   width: 3.5rem;
  //   height: 3.5rem;
  //   @apply flex items-center justify-center;
  // }


  .slider__nav {
    position: absolute;
    left: 0;
    bottom: 0;
    // transform: translateX(50%);
    z-index: 1;
    @apply flex items-end;
    .swiper-button-prev,
    .swiper-button-next {
      position: relative;
      left: inherit;
      right: inherit;
      top: inherit;
      background: #ffff;
      width: 7rem;
      height: 7rem;
      @apply flex items-center justify-center;
      &.swiper-button-disabled {
        cursor: initial;
        svg {
          opacity: .3;
        }
      }
    }
    &:before {
      content: '';
      width: 1px;
      height: 2.5rem;
      display: block;
      @apply bg-black;
      position: absolute;
      left: 50%;
      top: 50%;    
      transform: translate(-50%, -50%);
      z-index: 99;
    }
  }

  // .swiper-button-next,
  // .swiper-container-rtl .swiper-button-prev {
  // }

  // .swiper-button-prev,
  // .swiper-container-rtl .swiper-button-next {
  // }

  .swiper-pagination {
    width: 100%;
    position: initial;

    .swiper-pagination-bullet {
      margin: theme("spacing.xs");
      border-radius: unset;
      width: 60px;
      height: 4px;
      background: theme("colors.gray.default");

      &-active {
        background: theme("colors.text.1");
      }
    }
  }
  a.singol-image {
    display: block;
    margin: 0 auto;
}
}

.fancybox__counter {
  display: none;
}
