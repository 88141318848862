$hamburger-padding-x: 0rem;
$hamburger-padding-y: 0rem;
$hamburger-layer-width: 2.4rem;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: theme("colors.white");
$hamburger-layer-border-radius: 5px;
$hamburger-hover-opacity: 1;
$hamburger-active-layer-color: theme("colors.white");
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

@import "../../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

// @import "./v5/header";
@import "./v1/header";

.hamburger-box {
  // display: flex;
  // @apply bg-black;
}

