.woocommerce-gallery__wrapper {
	position: relative;
	overflow: hidden;
	.woocommerce-product-gallery > .flex-viewport,
	.woocommerce-product-gallery > figure {
		border: 1px solid theme('colors.gray.light');
		background-color: #fff;
		@apply rounded-md;
		overflow: hidden;
	}
	.product__notification {
		position: absolute;
		top: 1rem;
		right: 1rem;
		> div,
		> span {
			@apply rounded-full;
			@apply px-md;
			@apply py-xs;
			@extend .text-sm;
		}
		.onsale {
			@apply bg-support-success;
			color: #fff;
		}
    }
	img {
		width: 100%;
	}

	a.woocommerce-product-gallery__trigger {
		display: none;
		pointer-events: none;
	}
	img.zoomImg {
    	background-color: #fff;
	}
	&__image {
		cursor: zoom-in !important;
	}
	.flex-control-nav {
		@apply flex flex-wrap px-0;
		li {
			margin-top: 1rem;
			margin-right: 1rem;
			list-style: none;
			img {
				max-height: 45px;
				@apply rounded-sm border border-gray-light;
				@screen md {
					max-height: 72px;
				}
				padding: 5px;
				background-color: #fff;
				transition: .5s;
				cursor: pointer;
				&.flex-active,
				&:hover {
					@apply rounded-sm border-brand_01;
				}
			}
		}
	}
}


