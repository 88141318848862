/**
 * Deprecated
 * Fallback for bourbon equivalent
 */
@mixin clearfix() {
  *zoom: 1;

  &::before,
  &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin border_radius($radius: 4px) {
  border-radius: $radius;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin border_radius_right($radius: 4px) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin border_radius_left($radius: 4px) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin border_radius_bottom($radius: 4px) {
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin border_radius_top($radius: 4px) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin opacity($opacity: 0.75) {
  opacity: $opacity;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin box_shadow(
  $shadow_x: 3px,
  $shadow_y: 3px,
  $shadow_rad: 3px,
  $shadow_in: 3px,
  $shadow_color: #888
) {
  box-shadow: $shadow_x $shadow_y $shadow_rad $shadow_in $shadow_color;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin inset_box_shadow(
  $shadow_x: 3px,
  $shadow_y: 3px,
  $shadow_rad: 3px,
  $shadow_in: 3px,
  $shadow_color: #888
) {
  box-shadow: inset $shadow_x $shadow_y $shadow_rad $shadow_in $shadow_color;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin text_shadow(
  $shadow_x: 3px,
  $shadow_y: 3px,
  $shadow_rad: 3px,
  $shadow_color: #fff
) {
  text-smhadow: $shadow_x $shadow_y $shadow_rad $shadow_color;
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin vertical_gradient($from: #000, $to: #fff) {
  background-color: $from;
  background: -webkit-linear-gradient($from, $to);
}

/**
 * Deprecated
 * Vendor prefix no longer required.
 */
@mixin transition($selector: all, $animation: ease-in-out, $duration: 0.2s) {
  transition: $selector $animation $duration;
}

/**
 * Deprecated
 * Use bourbon mixin instead `@include transform(scale(1.5));`
 */
@mixin scale($ratio: 1.5) {
  -webkit-transform: scale($ratio);
  transform: scale($ratio);
}

/**
 * Deprecated
 * Use bourbon mixin instead `@include box-sizing(border-box);`
 */
@mixin borderbox() {
  box-sizing: border-box;
}

@mixin darkorlighttextshadow($a, $opacity: 0.8) {
  @if lightness($a) >= 65% {
    @include text_shadow(0, -1px, 0, rgba(0, 0, 0, $opacity));
  } @else {
    @include text_shadow(0, 1px, 0, rgba(255, 255, 255, $opacity));
  }
}

/**
 * Objects
 */
@mixin menu() {
  @include clearfix();

  li {
    display: inline-block;
  }
}

@mixin mediaright() {
  @include clearfix();

  img {
    float: right;
    height: auto;
  }
}

@mixin medialeft() {
  @include clearfix();

  img {
    float: right;
    height: auto;
  }
}

@mixin ir() {
  display: block;
  text-indent: -9999px;
  position: relative;
  height: 1em;
  width: 1em;
}

@mixin icon($glyph: "\e001") {
  font-family: "WooCommerce";
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin: 0;
  text-indent: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  content: $glyph;
}

@mixin icon_dashicons($glyph: "\f333") {
  font-family: "Dashicons";
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  text-indent: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  content: $glyph;
}

@mixin iconbefore($glyph: "\e001") {
  font-family: "WooCommerce";
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-right: 0.618em;
  content: $glyph;
  text-decoration: none;
}

@mixin iconbeforedashicons($glyph: "\f333") {
  font-family: "Dashicons";
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: $glyph;
  text-decoration: none;
}

@mixin iconafter($glyph: "\e001") {
  font-family: "WooCommerce";
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  margin-left: 0.618em;
  content: $glyph;
  text-decoration: none;
}

@mixin loader() {
  &::before {
    height: 1em;
    width: 1em;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.5em;
    margin-top: -0.5em;
    content: "";
    animation: spin 1s ease-in-out infinite;
    background: url("../images/icons/loader.svg") center center;
    background-size: cover;
    line-height: 1;
    text-align: center;
    font-size: 2em;
    color: rgba(#000, 0.75);
  }
}

/*account*/

.woocommerce-account {
  .col2-set {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 50%;
      padding: 2rem;
      box-sizing: border-box;
      @media (max-width: theme("screens.md")) {
        width: 100%;
        padding: 0 0 2rem;
      }
    }
  }
  ul li {
    list-style: none;
  }
  .woocommerce-MyAccount-navigation {
    float: left;
    width: 30%;
  }

  .woocommerce-MyAccount-content {
    float: right;
    width: 68%;
    a.woocommerce-Button.button {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  main > .woocommerce {
    @apply container-md;
    .woocommerce-MyAccount-navigation,
    .woocommerce-MyAccount-content {
      .button {
        @extend .button-v1;
      }
    }

    .woocommerce-MyAccount-navigation {
      // min-width: 250px;
      padding: 0px 0 4rem;

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        width: 100%;

        li {
          &::before {
            display: none;
          }

          a {
            // @extend .button-v1--sm;
            display: block;
            width: 100%;
            @apply mb-sm;
          }

          &.is-active {
            a {
              // @extend .button-v1--sm:hover;
            }
          }
        }
      }
    }

    .woocommerce-MyAccount-content {
      // flex: 1;
      // padding: 0px 40px;

      table {
        width: 100%;
        text-align: center;
      }
    }

    .u-columns {
      width: 100%;
    }
  }
}

table.my_account_orders {
  font-size: 0.85em;
  border-collapse: separate;
  border-spacing: 0 1em;

  th,
  td {
    padding: 4px 8px;
    vertical-align: middle;
  }

  .button {
    white-space: nowrap;
  }
}

table.woocommerce-MyAccount-downloads {
  td,
  th {
    vertical-align: top;
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: left;
    }

    .woocommerce-MyAccount-downloads-file::before {
      content: "\2193";
      display: inline-block;
    }
  }
}

.wc-bacs-bank-details-account-name {
  font-weight: bold;
}

/**
 * Account page
 */
.woocommerce-account {
  .woocommerce {
    @include clearfix();
  }

  .addresses .title {
    @include clearfix();

    h3 {
      float: left;
    }

    .edit {
      //float: right;
      @extend .button-v1;
      // @extend .button-v1--transparent;
      // @extend .button-v1--sm;
      margin: 0.5rem 0 1rem;
    }
  }

  ol.commentlist.notes li.note {
    p.meta {
      font-weight: 700;
      margin-bottom: 0;
    }

    .description p:last-child {
      margin-bottom: 0;
    }
  }

  ul.digital-downloads {
    margin-left: 0;
    padding-left: 0;

    li {
      list-style: none;
      margin-left: 0;
      padding-left: 0;

      &::before {
        @include iconbefore("\e00a");
      }

      .count {
        float: right;
      }
    }
  }
}

/*ortica*/
form.woocommerce-EditAccountForm.edit-account {
  display: flex;
  flex-wrap: wrap;
  fieldset {
    margin: 0;
    padding: 0;
    width: 100%;
    legend {
      // @extend .title-m;
      padding: 2rem 0.5rem 0;
      display: block;
    }
  }
}
.woocommerce-MyAccount-content {
  .form-row label {
    display: block;
  }
  .woocommerce-Message {
    .woocommerce-Button {
      width: 100%;
      display: block;
    }
  }
  .woocommerce-EditAccountForm {
    .button {
      margin: 1rem 0.5rem;
    }
  }
}

/*mobile*/

@media (max-width: theme("screens.md")) {
  table.my_account_orders {
    tr {
      td {
        &.order-actions {
          text-align: left;

          &::before {
            display: none;
          }

          .button {
            float: none;
            margin: 0.125em 0.25em 0.125em 0;
          }
        }
      }
    }
  }
  .lost_reset_password {
    .form-row-first,
    .form-row-last {
      width: 100%;
      float: none;
      margin-right: 0;
    }
  }

  .woocommerce-account {
    .woocommerce-MyAccount-navigation,
    .woocommerce-MyAccount-content {
      float: none;
      width: 100%;
    }
  }
}

.woocommerce-address-fields {
  &__field-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}
