.form-v1 {
  &--sfondo-iniziale {
    position: relative;
    &:before {
      @screen md {
        content: '';
        position: absolute;
        width: 100%;
        height: 12rem;
        top: 0;
        left: 0;
        @apply bg-gray-light;
        z-index: -1;
      }
    }
  }

  // iframe {
  //   @apply shadow-md;
  //   filter: grayscale(100%);
  // }
}



