.single_add_to_cart_button {
  @extend .button-v1;

  &.disabled {
    // @extend .button--disable;
    position: relative;
    pointer-events: none;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: .7;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
    }
  }
}
