.button-contatti {
    flex-direction: column;    
    aspect-ratio: 6 / 3;
    transition: .5s;
    @screen md {
        aspect-ratio: 6 / 4;
    }
    img {
        height: 3.5rem;
        width: auto;
        @apply mb-md;
        transition: .3s;
    }
    &:hover {
        @apply border-black;
        img {
            transform: scale(1.2);
        }
    }
}