.quantity {
  background-color: #fff;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 3.8rem;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid theme('colors.gray.light');
  @apply rounded-sm px-sm;

  input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    outline: none;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  &__minus,
  &__plus { 
    cursor: pointer;
    svg path {
      transition: .5s;
    }
    &:hover {
      svg {
        path {
          stroke: #000;
        }
      }
    }
  }
  &__btns {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    overflow: hidden;
    border-radius: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    svg {
      width: 8px;
      height: 5.2px;
      polyline {
        fill: none;
        stroke: white;
        stroke-width: 1;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
        transition: 0.2s;
      }
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background-color: theme("colors.text.1");
      appearance: none;
      &:hover {
        svg {
          polyline {
            stroke: theme("colors.gray.default");
          }
        }
      }
    }
  }


  .qty {
    // @extend .text-md;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align: center;
    max-width: 4rem;
    font-size: 20px;
    height: 100%;
    margin: 0;
    border: 0px;
    color: #000;
    background: transparent;
    padding: 0;
  }
}


.product {
  // .quantity {
  //   padding: .5rem;
  //   border-radius: 3rem 0 0 3rem;
  //   padding-right: 3rem;
  // }
  .single_add_to_cart_button {
    margin-left: 1rem;
  }
}
